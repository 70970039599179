import { Link } from 'react-router-dom'
import { useState, useEffect } from 'react'
import ActionTable from './ActionTable'
import RequestModal from './RequestModal'
import { useAuth } from '../util/AuthContext'
import { doGet, doPost } from '../util/RestCaller'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function NewRequest() {
	const auth = useAuth()

	const editRow = 'fa fa-edit fa-green'
	const removeRow = 'fa fa-ban fa-red'
	const [ formRows, setFormRows ] = useState([])
	const [ editMode, setEditMode ] = useState(false)
	const [ modalIsOpen, setModalIsOpen ] = useState(false)

	const [ formId , setFormId ] = useState('')
	const [ formName, setFormName ] = useState('1')
	const [ customName, setCustomName ] = useState('')
	const [ description, setDescription ] = useState('')	
	const [ formAssignee, setFormAssignee ] = useState('')
	const [ validUntil, setValidUntil ] = useState(new Date())	

	const [ assigneeOptions, setAssigneeOptions ] = useState([])

	useEffect(() => {
		async function getUserAssignees() {
			const resp = await doGet('/getUserMappings', {}, auth.user)
			console.log("Set assignee options This is resp ",resp)
			setAssigneeOptions(resp[0].assignees)
		}

		getUserAssignees()
	},[])

	const clearFormDetails = () => {
		setFormId('')
		setFormName('1')
		setCustomName('')
		setDescription('')
		setFormAssignee('')
		setValidUntil(new Date())
		setEditMode(false)		
	}

	const triggerClose = () => {
		setModalIsOpen(false)
	}	

	const triggerOpen = () => {
		clearFormDetails()
		setModalIsOpen(true)
		setFormAssignee(assigneeOptions[0])
	}	

	const addRequestItem = (requestItem) => {
		const existIndex = formRows.findIndex(e => e._id === requestItem._id)
		if(existIndex >= 0) {
			//splice around array
			setFormRows([...formRows.slice(0, existIndex), requestItem, ...formRows.slice(existIndex+1)])
		}
		else {
			//add into array
			setFormRows([...formRows, requestItem])
		}
	}

	const submitRows = async () => {
		if(formRows.length > 0) {
			console.log("Submitting Rows ",formRows)
			const resp = await doPost('/addRequests', {requests : formRows}, auth.user)
			console.log("This is resp ",resp)
			if(resp != null) {
				clearFormDetails()
				setFormRows([])
				toast.success("Requests were submitted!")
			}
		}
	}

	const handleRowClickAction = (e) => {
		console.log("You clicked on ",e.target.id)
		const targetId = e.target.id
		const targetParts = targetId.split("_")
		if(targetId.length < 3) {
			return
		}

		const formId = targetParts[0]
		const rowAction = targetParts[2]
		const foundIndex = formRows.findIndex(e => e._id === formId)

		switch(rowAction) {
			case "fa fa-edit fa-green":
				const formDetails = formRows[foundIndex]
				setFormId(formDetails._id)
				setFormName(formDetails.formName)
				if(formDetails.formName === 'Custom') {
					setCustomName(formDetails.customName)	
				}
				else {
					setCustomName('')
				}
				setDescription(formDetails.description)
				setFormAssignee(formDetails.assignee)
				setValidUntil(new Date(formDetails.validUntil))
				setEditMode(true)
				setModalIsOpen(true)
			break;
			case "fa fa-ban fa-red":
				setFormRows([...formRows.slice(0, foundIndex), ...formRows.slice(foundIndex+1)])			
			break;
			default:
			break;
		}

	}

	return (
		<div className='container'>
			<RequestModal
			modalIsOpen={modalIsOpen}
			editMode={editMode}
			triggerClose={triggerClose}
			addRequestItem={addRequestItem}
			formName={formName}
			setFormName={setFormName}
			customName={customName}
			setCustomName={setCustomName}
			description={description}
			setDescription={setDescription}
			assigneeOptions={assigneeOptions}
			formAssignee={formAssignee}
			setFormAssignee={setFormAssignee}
			validUntil={validUntil}
			setValidUntil={setValidUntil}
			formId={formId}
			/>
			<ActionTable
			name="Added Requests"
			rows={formRows}
			divCss="table-small"
			tableCss="table table-striped table-hover table-sm"
			rowActionButtons={[editRow, removeRow]}
			handleActionClick={handleRowClickAction}	
			/>
		  <button onClick={triggerOpen} className='btn btn-primary btn-sm'>New Request</button>						
		  <button disabled={formRows.length == 0} onClick={submitRows} className='btn btn-primary btn-sm float-right'>Submit</button>			
		</div>
	)
}

export default NewRequest
import './App.css';
import LoginPortal from './components/login/LoginPortal'
import NavBar from './components/login/NavBar'
import { BrowserRouter as Router, 
  Switch, 
  Route
} from 'react-router-dom'

import { PrivateRoute, ProvideAuth } from './components/util/AuthContext'
import { ToastContainer } from 'react-toastify'
import Dashboard from './components/portal/Dashboard'
import NewRequest from './components/portal/NewRequest'
import Requests from './components/portal/Requests'
import HistoryDash from './components/portal/HistoryDash'

function App() {
  return (
  <ProvideAuth>
    <Router>
        <NavBar/>
        <Switch>
          <Route path="/login">
              <LoginPortal/>
          </Route>
          <PrivateRoute path="/requests">
              <Requests/>
          </PrivateRoute>
          <PrivateRoute path="/approvals">
              <Dashboard/>
          </PrivateRoute>
          <PrivateRoute path="/history">
              <HistoryDash/>
          </PrivateRoute>
          <PrivateRoute path="/newRequests">
              <NewRequest/>
          </PrivateRoute>
        </Switch>
    </Router>
    <ToastContainer/>
  </ProvideAuth>  
  );
}

export default App;
export async function doGet(url, params, authContext) {
	//console.log("Doing get request ",authContext)
	return await fetch(host + url + "?" + new URLSearchParams(
	{
	    user : authContext.user,
	    token : authContext.token
	}),
	{ method : 'GET'}
	).then(resp => resp.json()).then(res=>res)
}


//'http://localhost:3001/login'
export async function doPost(url, params, authContext) {
	return await fetch(host + url, {
	  method: "POST",
	  body: JSON.stringify(Object.assign(params, authContext)),
	  headers: {"Content-type": "application/json; charset=UTF-8"}
	}).then(resp => resp.json()).then(res => res)
}

export const host = ""

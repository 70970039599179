import React from 'react'
import './login.css'
import {useState} from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useAuth } from '../util/AuthContext'

function LoginPortal(props) {

	const [user, setUser] = useState('')
	const [pass, setPass] = useState('')
	const history = useHistory()
	const location = useLocation()
	const auth = useAuth()
	const { from } = location.state || { from : {pathname : '/'}}
	const onInputChg = (e,cb) => {
		cb(e.target.value)
	}

    const login = () => {
	    auth.signin(() => {
	      history.replace(from);
	    }, user, pass);
	};
	
	return (
	    <div className='container centered-login login-box'>
	      <h1>Paperwork Portal</h1>
	      <div className='form-group'>
	        <label htmlFor='username'>User</label>
	        <input className='form-control' id='username' value={user} onChange={e => onInputChg(e, setUser)}/>
	      </div>
	      <div className='form-group'>
	        <label htmlFor='passentry'>Password</label>
	        <input className='form-control' type='password' id='passentry' placeholder='password is CaSe SeNsiTive' value={pass} onChange={e => onInputChg(e, setPass)}/>
	      </div>
	      <button onClick={login} className='btn btn-primary'>
	        Login
	      </button>
	    </div>
	)
}

export default LoginPortal
import { useState , useEffect } from 'react'
import ActionTable from './ActionTable'
import { doGet } from '../util/RestCaller'
import { useAuth } from '../util/AuthContext'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function HistoryDash() {

	const auth = useAuth()
	const [ historicalRecords, setHistoricalRecords ] = useState([])

	async function getHistoricalRecords() {
		const response = await doGet('/getHistoricalItems', {}, auth.user)
		console.log("Response is ",response)
		setHistoricalRecords(response)
		toast.success('Historical records loaded successfully')
	}

	useEffect(() => {
		getHistoricalRecords()
	},[])
	

	return (
		<div className='container-fluid'>
			<ActionTable
			name="Historical Records"
			rows={historicalRecords}
			divCss="table-top"
			rowActionButtons={[]}
			handleActionClick={()=>{}}
			handleRefreshClick={getHistoricalRecords}
			/>
		</div>
	)

}

export default HistoryDash
import { useState, useEffect } from 'react'
import { doGet } from '../util/RestCaller'
import { useAuth } from '../util/AuthContext'
import ActionTable from './ActionTable'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Requests() {
	const auth = useAuth()

	const [requests, setRequests] = useState([])

	async function getRequests() {
		const response = await doGet('/getRequests', {}, auth.user)
		console.log("Response is ",response)
		setRequests(response)
		toast.success('My Requests retrieved successfully')
	}

	useEffect(() => {
		getRequests()
	},[])

	return (
		<div className='container-fluid'>
			<ActionTable
			name="Active Requests"
			rows={requests}
			divCss="table-top"
			rowActionButtons={[]}
			handleActionClick={()=>{}}
			handleRefreshClick={getRequests}		
			/>
		</div>
	)
	
}

export default Requests
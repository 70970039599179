import { Route, Redirect } from 'react-router-dom'
import { useState, createContext, useContext } from 'react'
import { authenticator } from './Authenticator'

const authContext = createContext();

export function ProvideAuth({ children }) {
  const auth = useProvideAuth();
  return (
    <authContext.Provider value={auth}>
      {children}
    </authContext.Provider>
  );
}

export function PrivateRoute({ children, ...rest }) {
  let auth = useAuth()
  return (
    <Route
      {...rest}
      render={({ location }) =>
        auth.user ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location }
            }}
          />
        )
      }
    />
  );
}

function useProvideAuth() {
  const [user, setUser] = useState(null);

  const signin = (cb, u, p) => {
    return authenticator.signin((user) => {
      console.log("User principal details ",user)
      setUser(user);
      cb();
    }, u, p);
  };

  const signout = (cb, u) => {
    return authenticator.signout(() => {
      setUser(null);
      cb();
    }, u);
  };

  return {
    user,
    signin,
    signout
  };
}

export function useAuth() {
  return useContext(authContext)
}
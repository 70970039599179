function ActionTable(props) {

	const { name, rows, divCss, tableCss, rowActionButtons, handleActionClick, handleRefreshClick } = props;
	const shouldRenderActionRow = rowActionButtons.length > 0
	console.log("Rows are ",rows)

	const getRows = rows.map(val => (
		<tr className={(val.formStatus === "approved" ? 'bg-success' : (val.formStatus === "rejected" ? 'bg-danger' : ''))} key={val._id+"row"}>
	      { shouldRenderActionRow ? (<td>{rowActionButtons.map(rab => <i id={val._id+'_'+name+'_'+rab} key={rab+val} className={rab}></i>)}</td>) : null}
	      <td>{val.formName === 'Custom' ? val.customName : val.formName}</td>
	      <td>{val.description}</td>
	      <td>{new Date(val.validUntil).toLocaleString()}</td>
	      <td>{val.submitter}</td>
	      <td>{val.assignee}</td>
	      <td>{new Date(val.createTimestamp).toLocaleString()}</td>
	      <td>{new Date(val.lastUpdate).toLocaleString()}</td>
	    </tr>
	))

	const getRefreshIcon = handleRefreshClick ? (<i onClick={handleRefreshClick} className='fa fa-refresh'></i>) : null

	console.log("Rows are ",getRows)

	return (
		<div className={divCss}>
			<h3>{name} {getRefreshIcon}</h3>
			<table className={tableCss ? tableCss : "table table-striped table-hover"} onClick={handleActionClick}>
			  <thead>
			    <tr>
			      { shouldRenderActionRow ? (<th scope="col">Action</th>) : null}
			      <th scope="col">Form Name</th>
			      <th scope="col">Description</th>
			      <th scope="col">Valid Until</th>
			      <th scope="col">Petitioner</th>
			      <th scope="col">Assignee</th>
			      <th scope="col">Time Created</th>
			      <th scope="col">Last Update</th>
			    </tr>
			  </thead>
			  <tbody>
			  	{getRows}
			  </tbody>
			</table>
		</div>
	)
}

export default ActionTable
import Modal from 'react-modal'
import { useState, useEffect } from 'react'
import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css";
import { useAuth } from '../util/AuthContext'
import { v4 as uuidv4 } from 'uuid';
import './RequestModal.css'

Modal.setAppElement('#root')

function RequestModal(props) {
	const auth = useAuth()
	const { modalIsOpen, formName, description, formAssignee, validUntil, formId, customName, assigneeOptions } = props
	const { setFormName, setDescription, setFormAssignee, setValidUntil, setCustomName } = props
	const { editMode } = props

	const parseAndClose = () => {
		parseAndAddRequest()
		props.triggerClose()
	}

	const getActionButtons = () => {
		if(editMode) {
			return (
			  <button onClick={parseAndClose} className='btn btn-primary btn-sm'>Update</button>
			)
		}
		else {
			return (
			<div>
			  <button onClick={parseAndAddRequest} className='btn btn-primary btn-sm'>Add More</button>
			  <button onClick={parseAndClose} className='btn btn-primary btn-sm margin-left-five'>Add And Close</button>
			</div>  
			)
		}
	}

	const assigneeOpts = assigneeOptions.map(ass => (<option key={ass+'option'}>{ass}</option>))

	const renderCustomNameField = () => {
		if(formName === 'Custom') {
			return (
			  <div className="form-group">
			    <label htmlFor="custom-name">Custom Name</label>
			    <input type="email" className="form-control form-control-sm" id="custom-name" placeholder="custom name" value={customName} onChange={(e) => onChg(e, setCustomName)}/>
			  </div>				
			)
		}
		else {
			return null
		}
	}

	const onChg = (e, cb) => {
		const changedValue = e.target.value
		cb(changedValue)
	} 

	const parseAndAddRequest = () => {
		const now = new Date().getTime()
		const user = auth.user.user
		const id = editMode ? formId : uuidv4()
		const assignee = assigneeOptions.length == 1 ? assigneeOptions[0] : formAssignee
 		const requestItem = Object.assign({}, {_id : id}, { formName, description, customName }, { assignee : assignee, createTimestamp : now, lastUpdate : now, submitter : user, validUntil : validUntil.getTime(), formStatus : 'pending'})
		console.log("This is request item ",requestItem)
		props.addRequestItem(requestItem)
	}

	return (
		<div>
	        <Modal
	          isOpen={modalIsOpen}
	          contentLabel="Example Modal"
	        >		
	          <i onClick={props.triggerClose} className='fa fa-close fa-red float-right'></i>
			  <div className="form-group">
			    <label htmlFor="form-name">Form Name</label>
			    <select className="form-control form-control-sm" id="form-name" value={formName} onChange={(e) => onChg(e, setFormName)}>
			      <option>1</option>
			      <option>2</option>
			      <option>3</option>
			      <option>4</option>
			      <option>Custom</option>
			    </select>
			  </div>	
			  {renderCustomNameField()}	
			  <div className="form-group">
			    <label htmlFor="form-desc">Request Description</label>
			    <input type="text" className="form-control form-control-sm" id="form-desc" placeholder="request details" value={description} onChange={(e) => onChg(e, setDescription)}/>
			  </div>
			  <div className="form-group">
			    <label htmlFor="form-assignee">Assignee</label>
			    <select className="form-control form-control-sm" id="form-assignee" value={formAssignee} onChange={(e) => onChg(e, setFormAssignee)}>
			    	{assigneeOpts}
			    </select>
			  </div>
			  <div className='form-group'>
			  	<label>Valid Until</label>
			  	<div>
					<DatePicker
					  selected={validUntil}
					  onChange={setValidUntil}
					  showTimeSelect
					  dateFormat="Pp"
					/>
				</div>
			  </div>
			  {getActionButtons()}
		  </Modal>
		  </div>
	)		
	
}

export default RequestModal
import './Dashboard.css'
import ActionTable from './ActionTable'
import { useState, useEffect } from 'react' 
import { useAuth } from '../util/AuthContext'
import { doGet, doPost } from '../util/RestCaller'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Dashboard() {
	const auth = useAuth()

	const thumbsUp = 'fa fa-thumbs-up fa-green'
	const thumbsDown = 'fa fa-thumbs-down fa-red'
	const remove = 'fa fa-ban fa-red'

	async function fetchData() {
		const response = await doGet('/getActiveItems', {}, auth.user)
		console.log("Active items req ",response)
		setTableItems(response)	
		toast.success("Active items retrieved successfully")		
	}	

	const [ tableItems, setTableItems ] = useState([])

	useEffect(() => {
		fetchData()
	},[])

	const updateItem = async (formId, isApproveAction) => {
		const resp = await doPost('/updateItem', {formId, isApproveAction}, auth.user)
		setTableItems(resp)
	}

	const removeItem = async(formId) => {
		const resp = await doPost('/removeItem', {formId}, auth.user)
		setTableItems(resp)
		toast.success("Active item removed successfully")
	}

	const handleActionClick = (e) => {
		const clickId = e.target.id

		if(clickId.length > 0) {
			const clickParts = clickId.split('_')
			console.log("click parts ",clickParts)
			const formId = clickParts[0]
			const actionType = clickParts[2]

			switch(actionType) {
				case thumbsUp:
					updateItem(formId, true)
				break;
				case thumbsDown:
					updateItem(formId, false)
				break;
				case remove:
					removeItem(formId)
				break;
				default:
				break;
			}
		}
	}

	return (
		<div className='container-fluid'>
			<ActionTable
			name="Pending Items"
			rows={tableItems}
			divCss="table-top"
			rowActionButtons={[thumbsUp, thumbsDown, remove]}
			handleActionClick={handleActionClick}
			handleRefreshClick={fetchData}			
			/>	
		</div>
	)
}

export default Dashboard
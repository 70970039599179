import { Link } from 'react-router-dom'
import { useAuth } from '../util/AuthContext'

function NavBar() {
  const auth = useAuth()

  const logout = () => {
      auth.signout(() => {
      }, auth.user);
  };

  return (
    <nav className="navbar navbar-expand-lg navbar-dark bg-primary">
      <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarColor01" aria-controls="navbarColor01" aria-expanded="false" aria-label="Toggle navigation">
        <span className="navbar-toggler-icon"></span>
      </button>    
      <div className="collapse navbar-collapse hide" id="navbarColor01">
        <ul className="navbar-nav mr-auto">
          <li className="nav-item">
            <Link to='/approvals'>
              <label data-toggle="collapse" data-target="#navbarColor01" className="nav-link">My Approvals</label>
            </Link>
          </li>
          <li className="nav-item">
            <Link to='/requests'>
              <label data-toggle="collapse" data-target="#navbarColor01" className="nav-link">My Requests</label>
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/history">
              <label data-toggle="collapse" data-target="#navbarColor01" className="nav-link">My History</label>
            </Link>
          </li>
          <li className='nav-item'>
            <Link to='/newRequests'>
              <label data-toggle="collapse" data-target="#navbarColor01" className='nav-link'>New Request</label>
            </Link>
          </li>
        </ul>
        <label hidden={auth.user == null} onClick={logout} data-toggle="collapse" data-target="#navbarColor01" className='btn btn-primary'>Logout</label>
      </div>
    </nav>  
  )
}

export default NavBar
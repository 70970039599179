import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {doPost} from './RestCaller'

async function signin(cb, u, p) {
  const principal = { user : u , pass : btoa(p)}
  const authToken = await doPost('/login', principal, {})
  if(authToken.token == null) {
    errorMessage()
  }
  else {
    successMessage(() => cb(authToken), "User login successful")
  }
}

async function signout(cb, authContext) {
  const response = await doPost('/logout', {}, authContext)
  if(response == null) {
    errorMessage()
  }
  else {
    successMessage(() => cb(), "User logout successful")
  }
}

function successMessage(cb, text) {
	toast.success(text)
	cb()
}

function errorMessage() {
  toast.error("User failed login")
}

export const authenticator = {
	signin, signout
}

/*
fetch('http://localhost:3001/login', {
  method: "POST",
  body: JSON.stringify({user : 'Richard', pass : 'ZGVm'}),
  headers: {"Content-type": "application/json; charset=UTF-8"}
}).then(resp => resp.json()).then(res => console.log(res))
*/